import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { resetState } from '@/composables/messages';


export default /*@__PURE__*/_defineComponent({
  __name: 'ClearMessage',
  setup(__props) {

resetState();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})