import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "output d__flex flex-dir__col" }
const _hoisted_2 = { class: "d__flex flex-dir__row terminal-input__cached" }
const _hoisted_3 = { class: "user vibrant-alien-yellow" }
const _hoisted_4 = { class: "location light-alien-blue" }
const _hoisted_5 = { class: "white" }
const _hoisted_6 = { class: "light-alien-green" }
const _hoisted_7 = { class: "d__flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.user), 1),
      _createElementVNode("span", _hoisted_4, [
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "white" }, "@", -1)),
        _createTextVNode(_toDisplayString(_ctx.location), 1)
      ]),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.preInput), 1),
      _cache[1] || (_cache[1] = _createElementVNode("span", null, " ", -1)),
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.inputMessage), 1)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}