import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "message" }
const _hoisted_2 = { class: "light-alien-green" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "white" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "white" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { key: 2 }
const _hoisted_10 = { class: "white" }
const _hoisted_11 = { class: "my-2" }
const _hoisted_12 = { class: "ml-2" }
const _hoisted_13 = ["href"]
const _hoisted_14 = { key: 3 }
const _hoisted_15 = { class: "white" }
const _hoisted_16 = ["src"]
const _hoisted_17 = { key: 4 }
const _hoisted_18 = { class: "white" }
const _hoisted_19 = { key: 5 }
const _hoisted_20 = { class: "white" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = {
  key: 1,
  class: "mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _cache[9] || (_cache[9] = _createElementVNode("strong", null, " Upcoming Performances: ", -1)),
      _cache[10] || (_cache[10] = _createElementVNode("br", null, null, -1)),
      _cache[11] || (_cache[11] = _createTextVNode(" ______________________ ")),
      (_ctx.futureShows.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.futureShows, (show) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "ff__dina mt-2 mb-2",
                key: show.date
              }, [
                _createElementVNode("strong", null, _toDisplayString(show.date), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(show), (key) => {
                  return (_openBlock(), _createElementBlock("p", { key: key }, [
                    (key === 'location')
                      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                          _createElementVNode("span", _hoisted_5, [
                            _createTextVNode("> " + _toDisplayString(key.toUpperCase()) + " ", 1),
                            _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1))
                          ]),
                          _createElementVNode("div", null, ">> " + _toDisplayString(show.location.venue), 1),
                          _createElementVNode("div", null, ">> " + _toDisplayString(show.location.address), 1)
                        ]))
                      : (key === 'tickets')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                            _createElementVNode("span", _hoisted_7, [
                              _createTextVNode("> " + _toDisplayString(key.toUpperCase()) + " ", 1),
                              _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1))
                            ]),
                            _createElementVNode("span", null, [
                              _cache[2] || (_cache[2] = _createTextVNode(">> ")),
                              _createElementVNode("a", {
                                target: "_blank",
                                class: "dark-alien-purple",
                                href: show.tickets?.link
                              }, _toDisplayString(show.tickets?.site), 9, _hoisted_8)
                            ])
                          ]))
                        : (key === 'bands')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, "> " + _toDisplayString(key.toUpperCase()), 1),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(show[key], (band, i) => {
                                return (_openBlock(), _createElementBlock("span", { key: i }, [
                                  _createElementVNode("div", _hoisted_11, ">> " + _toDisplayString(band.band), 1),
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(band['links'], (link, i) => {
                                    return (_openBlock(), _createElementBlock("span", {
                                      class: "",
                                      key: i
                                    }, [
                                      _createElementVNode("span", _hoisted_12, [
                                        _createElementVNode("a", {
                                          class: "dark-alien-purple",
                                          target: "_blank",
                                          href: link.link
                                        }, _toDisplayString(link.social), 9, _hoisted_13)
                                      ])
                                    ]))
                                  }), 128)),
                                  _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
                                ]))
                              }), 128))
                            ]))
                          : (key === 'flyer')
                            ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                                _createElementVNode("span", _hoisted_15, [
                                  _createTextVNode("> " + _toDisplayString(key.toUpperCase()) + " ", 1),
                                  _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1))
                                ]),
                                _createElementVNode("span", null, [
                                  _cache[5] || (_cache[5] = _createTextVNode(">> ")),
                                  _createElementVNode("img", {
                                    class: "flyer",
                                    src: show.flyer,
                                    alt: ""
                                  }, null, 8, _hoisted_16)
                                ])
                              ]))
                            : (key === 'start' || key === 'end')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
                                  _createElementVNode("span", _hoisted_18, [
                                    _createTextVNode("> " + _toDisplayString(key.toUpperCase()) + " ", 1),
                                    _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1))
                                  ]),
                                  _createElementVNode("span", null, ">> " + _toDisplayString(_ctx.prettyPrintTime(show[key], show.date)), 1)
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_19, [
                                  _createElementVNode("span", _hoisted_20, [
                                    _createTextVNode("> " + _toDisplayString(key.toUpperCase()) + " ", 1),
                                    _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1))
                                  ]),
                                  _createElementVNode("span", null, ">> " + _toDisplayString(show[key as keyof Show]), 1)
                                ]))
                  ]))
                }), 128)),
                _createElementVNode("div", null, [
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.saveToCalendar(show)),
                    class: "btn-primary"
                  }, "save to calendar", 8, _hoisted_21)
                ])
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_22, _cache[8] || (_cache[8] = [
            _createElementVNode("em", null, "No upcoming shows", -1)
          ])))
    ])
  ]))
}