import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d__flex flex-dir__row terminal-input__cached" }
const _hoisted_2 = { class: "user vibrant-alien-yellow" }
const _hoisted_3 = { class: "location light-alien-blue" }
const _hoisted_4 = { class: "white" }
const _hoisted_5 = { class: "light-alien-green input-container w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.user), 1),
    _createElementVNode("span", _hoisted_3, [
      _cache[2] || (_cache[2] = _createElementVNode("span", { class: "white" }, "@", -1)),
      _createTextVNode(_toDisplayString(_ctx.location), 1)
    ]),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.preInput), 1),
    _createElementVNode("span", _hoisted_5, [
      _createElementVNode("form", {
        name: "command",
        onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.showMessage && _ctx.showMessage(...args)), ["prevent"]), ["enter"])),
        "data-netlify": "true"
      }, [
        _withDirectives(_createElementVNode("input", {
          autocapitalize: "off",
          autocomplete: "off",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.command) = $event)),
          class: _normalizeClass({ valid: _ctx.doesCommandExist }),
          ref: "terminalInput",
          type: "text",
          id: "terminalInput"
        }, null, 2), [
          [_vModelText, _ctx.command]
        ])
      ], 32)
    ])
  ]))
}