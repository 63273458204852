import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "d__flex white ml-4" }
const _hoisted_2 = {
  key: 0,
  class: "message"
}
const _hoisted_3 = { class: "mt-3 d__flex" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "ml-4 mt-2 d__flex" }
const _hoisted_6 = { class: "font-size-13 ml-2" }
const _hoisted_7 = {
  key: 1,
  class: "message"
}
const _hoisted_8 = { class: "mt-3 d__flex" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "ml-4 mt-2 d__flex" }
const _hoisted_11 = { class: "font-size-13 ml-2" }
const _hoisted_12 = {
  key: 2,
  class: "message"
}
const _hoisted_13 = { class: "mt-3 d__flex" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "ml-4 mt-2 d__flex" }
const _hoisted_16 = { class: "font-size-13 ml-2" }
const _hoisted_17 = {
  key: 3,
  class: "message"
}
const _hoisted_18 = { class: "mt-3 d__flex" }
const _hoisted_19 = ["disabled"]
const _hoisted_20 = { class: "ml-4 mt-2 d__flex" }
const _hoisted_21 = { class: "font-size-13 ml-2" }
const _hoisted_22 = {
  key: 4,
  class: "message"
}
const _hoisted_23 = { class: "ml-3 mt-3 font-size-14" }
const _hoisted_24 = { class: "ml-3 mt-3 font-size-14" }
const _hoisted_25 = { class: "ml-3 mt-3 font-size-14" }
const _hoisted_26 = { class: "ml-3 mt-3 font-size-14" }
const _hoisted_27 = {
  key: 0,
  class: "ml-4 mt-2 d__flex"
}
const _hoisted_28 = ["disabled"]
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { class: "font-size-13 ml-2" }
const _hoisted_31 = {
  key: 1,
  class: "ml-4 mt-2"
}
const _hoisted_32 = { class: "white font-size-14" }

import Loader from "@/components/AnimatedLoader.vue";
import {
  ref,
  reactive,
  onMounted,
  nextTick,
  computed,
} from "vue";
import { type SendEmailPayload, sendEmail } from "@/services/aws/lambda";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactMessage',
  setup(__props, { expose: __expose }) {

const step = ref(0);
const completedForm = ref(false);
const input0 = ref<HTMLInputElement>();
const closed = ref(false);
const loading = ref(false);

const data = reactive<SendEmailPayload>({
  subject: "",
  message: "",
  returnAddress: "",
  from: "",
});

const formErrors = reactive<Record<string, string>>({
  subject: "",
  message: "",
  returnAddress: "",
  from: "",
});

const submissionResponse = ref("");

onMounted((): void => {
  input0.value?.focus();
});

const isSubjectValid = computed((): boolean => data.subject !== "");
const isMessageValid = computed((): boolean => data.message !== "");
const isValidEmail = computed((): boolean => data.returnAddress.includes("@"));
const isValidFrom = computed((): boolean => data.from !== "");

const validateField = (field: string): void => {
  if (field === "subject" && !isSubjectValid.value) {
    formErrors[field] = "The subject field can not be empty";
  } else if (field === "message" && !isMessageValid.value) {
    formErrors[field] = "The message field can not be empty";
  } else if (field === "returnAddress" && !isValidEmail.value) {
    formErrors[field] = "The address field must be valid";
  } else if (field === "from" && !isValidFrom.value) {
    formErrors[field] = "The from field can not be empty";
  }
};

const progress = (key: string): void => {
  formErrors[key] = "";

  validateField(key);

  if (formErrors[key] !== "") return;

  if (closed.value) return;

  if (completedForm.value) {
    step.value = 4;
    return;
  }

  step.value++;

  if (step.value === 4) {
    completedForm.value = true;
  }

  nextTick((): void => {
    if (step.value <= 3) {
      (document.querySelector(".secondary-input") as HTMLInputElement).focus();
    }
  });
};

const submit = async (): Promise<void> => {
  if (
    !isSubjectValid.value ||
    !isMessageValid.value ||
    !isValidEmail.value ||
    !isValidFrom.value
  )
    return;

  try {
    loading.value = true;
    submissionResponse.value = "";

    const response = await sendEmail(data);
    if (response.success) {
      submissionResponse.value = "message sent";
      closed.value = true;
    }
  } catch (err) {
    if (err instanceof Error) {
      submissionResponse.value = err.message;
      return;
    }

    submissionResponse.value = "Failed to send message";
  } finally {
    loading.value = false;
  }
};

const update = (stepToUpdate: number): void => {
  if (closed.value) return;
  step.value = stepToUpdate;
};

const closeForm = (): void => {
  closed.value = true;
};

__expose({
  closeForm,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (step.value === 0)
      ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _cache[16] || (_cache[16] = _createElementVNode("div", null, "---- Subject", -1)),
          _createElementVNode("div", _hoisted_3, [
            _cache[15] || (_cache[15] = _createElementVNode("span", { class: "caret mr-2" }, ">", -1)),
            _withDirectives(_createElementVNode("input", {
              disabled: closed.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((data.subject) = $event)),
              onKeypress: _cache[1] || (_cache[1] = _withKeys(($event: any) => (progress('subject')), ["enter"])),
              ref_key: "input0",
              ref: input0,
              placeholder: "enter subject",
              class: "secondary-input",
              type: "text"
            }, null, 40, _hoisted_4), [
              [_vModelText, data.subject]
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (!closed.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (progress('subject'))),
                  class: "btn-primary"
                }, " next -> "))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, _toDisplayString(formErrors.subject), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (step.value === 1)
      ? (_openBlock(), _createElementBlock("section", _hoisted_7, [
          _cache[18] || (_cache[18] = _createElementVNode("div", null, "---- Message", -1)),
          _createElementVNode("div", _hoisted_8, [
            _cache[17] || (_cache[17] = _createElementVNode("span", { class: "caret mr-2" }, ">", -1)),
            _withDirectives(_createElementVNode("textarea", {
              disabled: closed.value,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((data.message) = $event)),
              ref: "input1",
              class: "secondary-input text-area",
              placeholder: "enter message"
            }, null, 8, _hoisted_9), [
              [_vModelText, data.message]
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            (!closed.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (progress('message'))),
                  class: "btn-primary"
                }, " next -> "))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_11, _toDisplayString(formErrors.message), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (step.value === 2)
      ? (_openBlock(), _createElementBlock("section", _hoisted_12, [
          _cache[20] || (_cache[20] = _createElementVNode("div", null, "---- Return Address", -1)),
          _createElementVNode("div", _hoisted_13, [
            _cache[19] || (_cache[19] = _createElementVNode("span", { class: "caret mr-2" }, ">", -1)),
            _withDirectives(_createElementVNode("input", {
              disabled: closed.value,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((data.returnAddress) = $event)),
              onKeypress: _cache[6] || (_cache[6] = _withKeys(($event: any) => (progress('returnAddress')), ["enter"])),
              ref: "input2",
              placeholder: "enter email",
              class: "secondary-input",
              type: "text"
            }, null, 40, _hoisted_14), [
              [_vModelText, data.returnAddress]
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            (!closed.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (progress('returnAddress'))),
                  class: "btn-primary"
                }, " next -> "))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_16, _toDisplayString(formErrors.returnAddress), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (step.value === 3)
      ? (_openBlock(), _createElementBlock("section", _hoisted_17, [
          _cache[22] || (_cache[22] = _createElementVNode("div", null, "---- From", -1)),
          _createElementVNode("div", _hoisted_18, [
            _cache[21] || (_cache[21] = _createElementVNode("span", { class: "caret mr-2" }, ">", -1)),
            _withDirectives(_createElementVNode("input", {
              disabled: closed.value,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((data.from) = $event)),
              onKeypress: _cache[9] || (_cache[9] = _withKeys(($event: any) => (progress('from')), ["enter"])),
              ref: "input3",
              placeholder: "enter name",
              class: "secondary-input",
              type: "text"
            }, null, 40, _hoisted_19), [
              [_vModelText, data.from]
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            (!closed.value)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (progress('from'))),
                  class: "btn-primary"
                }, " next -> "))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_21, _toDisplayString(formErrors.from), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    (step.value === 4)
      ? (_openBlock(), _createElementBlock("section", _hoisted_22, [
          _cache[27] || (_cache[27] = _createElementVNode("div", null, "---- Review", -1)),
          _cache[28] || (_cache[28] = _createElementVNode("div", { class: "font-size-13 white" }, "click section to update", -1)),
          _createElementVNode("div", {
            onClick: _cache[11] || (_cache[11] = ($event: any) => (update(0))),
            class: "mt-2 mb-4"
          }, [
            _cache[23] || (_cache[23] = _createStaticVNode("<div class=\"header-container light-alien-green font-size-15 d__flex\" data-v-be75e1f4><div class=\"caret-container mr-2\" data-v-be75e1f4><span class=\"c-1\" data-v-be75e1f4>&gt;</span><span class=\"c-2\" data-v-be75e1f4>&gt;</span><span class=\"c-3\" data-v-be75e1f4>&gt;</span></div> Subject </div>", 1)),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("em", null, _toDisplayString(data.subject), 1)
            ])
          ]),
          _createElementVNode("div", {
            onClick: _cache[12] || (_cache[12] = ($event: any) => (update(1))),
            class: "mt-2 mb-4"
          }, [
            _cache[24] || (_cache[24] = _createStaticVNode("<div class=\"header-container light-alien-green font-size-15 d__flex\" data-v-be75e1f4><div class=\"caret-container mr-2\" data-v-be75e1f4><span class=\"c-1\" data-v-be75e1f4>&gt;</span><span class=\"c-2\" data-v-be75e1f4>&gt;</span><span class=\"c-3\" data-v-be75e1f4>&gt;</span></div> Message </div>", 1)),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("em", null, _toDisplayString(data.message), 1)
            ])
          ]),
          _createElementVNode("div", {
            onClick: _cache[13] || (_cache[13] = ($event: any) => (update(2))),
            class: "mt-2 mb-4"
          }, [
            _cache[25] || (_cache[25] = _createStaticVNode("<div class=\"header-container light-alien-green font-size-15 d__flex\" data-v-be75e1f4><div class=\"caret-container mr-2\" data-v-be75e1f4><span class=\"c-1\" data-v-be75e1f4>&gt;</span><span class=\"c-2\" data-v-be75e1f4>&gt;</span><span class=\"c-3\" data-v-be75e1f4>&gt;</span></div> Return Address </div>", 1)),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("em", null, _toDisplayString(data.returnAddress), 1)
            ])
          ]),
          _createElementVNode("div", {
            onClick: _cache[14] || (_cache[14] = ($event: any) => (update(3))),
            class: "mt-2 mb-4"
          }, [
            _cache[26] || (_cache[26] = _createStaticVNode("<div class=\"header-container light-alien-green font-size-15 d__flex\" data-v-be75e1f4><div class=\"caret-container mr-2\" data-v-be75e1f4><span class=\"c-1\" data-v-be75e1f4>&gt;</span><span class=\"c-2\" data-v-be75e1f4>&gt;</span><span class=\"c-3\" data-v-be75e1f4>&gt;</span></div> From </div>", 1)),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("em", null, _toDisplayString(data.from), 1)
            ])
          ]),
          (!closed.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                (!closed.value)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      disabled: loading.value,
                      onClick: submit,
                      class: "btn-primary"
                    }, [
                      (!loading.value)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_29, "submit"))
                        : (_openBlock(), _createBlock(Loader, { key: 1 }))
                    ], 8, _hoisted_28))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_30, _toDisplayString(submissionResponse.value), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_31, [
                _createElementVNode("p", _hoisted_32, _toDisplayString(submissionResponse.value), 1)
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})