import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "audio-player-container",
  class: "mb-3 mr-3"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "container-title" }
const _hoisted_4 = {
  ref: "titleContainer",
  class: "title-container"
}
const _hoisted_5 = { class: "d__flex" }
const _hoisted_6 = {
  key: 0,
  id: "loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("audio", {
      onTimeupdate: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateSlider && _ctx.updateSlider(...args))),
      preload: "none",
      ref: "audio",
      loop: ""
    }, [
      _createElementVNode("source", {
        src: _ctx.source.source
      }, null, 8, _hoisted_2)
    ], 544),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.extension.toUpperCase()), 1),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("p", {
        ref: "songTitle",
        class: "song-title"
      }, _toDisplayString(_ctx.formattedTitle), 513)
    ], 512),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[5] || (_cache[5] = [
            _createElementVNode("div", { class: "loader-bar" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.isPaused && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("svg", {
            key: 1,
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.togglePlayback && _ctx.togglePlayback(...args))),
            id: "play-icon",
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 26 26"
          }, _cache[6] || (_cache[6] = [
            _createElementVNode("polygon", {
              class: "play-btn__svg",
              points: "9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69"
            }, null, -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.isPlaying && !_ctx.loading)
        ? (_openBlock(), _createElementBlock("svg", {
            key: 2,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.togglePlayback && _ctx.togglePlayback(...args))),
            id: "pause-icon",
            width: "100",
            viewBox: "0 0 108 108",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }, _cache[7] || (_cache[7] = [
            _createElementVNode("rect", {
              x: "64",
              y: "25",
              width: "20",
              height: "50"
            }, null, -1),
            _createElementVNode("rect", {
              x: "24",
              y: "25",
              width: "20",
              height: "50"
            }, null, -1)
          ])))
        : _createCommentVNode("", true),
      _withDirectives(_createElementVNode("input", {
        onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.updateAudioTimeStamp && _ctx.updateAudioTimeStamp(...args))),
        type: "range",
        id: "seek-slider",
        min: "0",
        max: "100",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.rangeData) = $event)),
        step: "1"
      }, null, 544), [
        [_vModelText, _ctx.rangeData]
      ])
    ])
  ]))
}