import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message" }
const _hoisted_2 = { class: "light-alien-green" }
const _hoisted_3 = { class: "ff__dina mt-3 ml-3" }
const _hoisted_4 = { class: "d__flex" }
const _hoisted_5 = { class: "ff__dina mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", null, [
        _createElementVNode("strong", null, "Useage"),
        _createTextVNode(" [command] ")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Commands", -1)),
        _cache[1] || (_cache[1] = _createElementVNode("div", null, " ______________________ ", -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.commandsPretty), 1)
        ])
      ])
    ])
  ]))
}