import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bg__dark-alien-black w-100vw h-100 terminal" }
const _hoisted_2 = {
  ref: "terminalInner",
  class: "terminal-inner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TerminalInput = _resolveComponent("TerminalInput")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_KeepAlive, null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message, i) => {
          return (_openBlock(), _createBlock(_resolveDynamicComponent(message.cachedInput), {
            key: i,
            inputMessage: message.input
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(message.component), {
                ref_for: true,
                ref: _ctx.setComponentRef
              }, null, 512))
            ]),
            _: 2
          }, 1032, ["inputMessage"]))
        }), 128))
      ], 1024)),
      _createVNode(_component_TerminalInput, {
        onSendCommand: _ctx.loadComponent,
        onError: _ctx.loadDefaultErrComponent
      }, null, 8, ["onSendCommand", "onError"])
    ], 512)
  ]))
}